import { db, firebase, GeoFirestore } from '../Firebase';
import { get_group_data } from '../methods/Group_Functions';
import { send_comment_added_notification, send_event_added_mod_notification, send_event_date_changed, send_event_invite_notification, send_event_removed_mod_notification, send_event_request_accepted_notification, send_event_request_notification, send_new_joiner_notification } from './Notification_Functions';
import { get_user_data } from './User_Functions';


const geocollection = GeoFirestore.collection('events');


const create_event = async ({name, details, address, slots, private_status, date, time, group_id, image, id, geo, admin}) => { 

    const group = group_id ? await get_group_data(group_id) : null;
    const group_members = group_id ? group.members.filter(item => item !== admin) : [];
    
    const event_info = {
      id: id,
      name: name,
      private_status: private_status,
      slots: slots,
      location: address,
      details: details,
      image: image, 
      time: time,
      ticket: null,
      start: firebase.firestore.Timestamp.fromDate(new Date(`${date}T${time}:00`)),
      template: true,
      admin: admin,
      members: [admin], 
      group_id: group_id ? group_id : null,
      mods: [],
      invites: group_members,
      declined: [],   
      requests: [],
    };

    if(group_id) { group_members.forEach(item => send_event_invite_notification(event_info, item)) }
    
    return await db.collection("events").doc(id).set(event_info)
      .then(async () => {
        await geo_add_data(id, geo)
        return id; 
      })
      .catch(error => {
        return error.message
      });
  }

  const geo_add_data = async (event_id, geo) => {
    geocollection.doc(event_id).update({
      coordinates: new firebase.firestore.GeoPoint(geo.latitude, geo.longitude)
    })
  }


  const update_my_event = async (event_id, new_info) => {
    if(new_info.date && new_info.time) {
        send_event_date_changed(event_id);
        return db.collection("events").doc(event_id).update({
          ...new_info, 
          start: firebase.firestore.Timestamp.fromDate(new Date(`${new_info.date}T${new_info.time}:00`))
        })
      } else if(new_info.geo) {
          return db.collection("events").doc(event_id).update(new_info)
          .then(async () => await geo_add_data(event_id, new_info.geo))
      } else {
          return db.collection("events").doc(event_id).update(new_info);
      }
  }

  const update_event_location = async (event_id, new_info) => {
    if(new_info.date && new_info.time) {
        send_event_date_changed(event_id);
        return db.collection("events").doc(event_id).update({
          ...new_info, 
          start: firebase.firestore.Timestamp.fromDate(new Date(`${new_info.date}T${new_info.time}:00`))
        })
      } else if(new_info.geo) {
          return db.collection("events").doc(event_id).update(new_info)
          .then(async () => await geo_add_data(event_id, new_info.geo))
      } else {
          return db.collection("events").doc(event_id).update(new_info);
      }
  }


  const get_events = async () => {
    const current_date = new Date();
    return await db.collection("events").where("private_status", "==", false).where("start", ">=", current_date).get().then(async (querySnapshot) => {
        return await Promise.all(querySnapshot.docs.map(async (doc) => {
            const d = doc.data();
            const organiser = d.group_id ? await get_group_data(d.group_id) : await get_user_data(d.admin);
            return {...d, organiser}  
        }));
      });
  }

  const get_event_data = async (event_id) => {
    const event_data = await db.collection("events").doc(event_id).get().then(async (doc) => {
        const d = doc.data();
        const organiser = d.group_id ? await get_group_data(d.group_id) : await get_user_data(d.admin);
        return {...d, organiser}  
    });
    const comments = await get_event_comments(event_id);
    return {...event_data, comments};
}


  const get_event_comments = async (event_id) => {
    return db.collection("events").doc(event_id).collection("comments").get().then(querySnapshot => {
        return querySnapshot.docs.map(doc => doc.data().comment)
      })
  }

  const add_event_comment = async (event_id, event_name, user_data, comment_id, comment_content) => {
    return db.collection("events").doc(event_id).collection("comments").doc(comment_id).set({
      ref_id: user_data.id,
      send_id: event_id,
      send_name: event_name,
      content: comment_content,
      comment_id: comment_id,
      created: firebase.firestore.Timestamp.fromDate(new Date())
    }).then(() => {
      send_comment_added_notification(user_data, event_id, comment_content)

      return true
    }).catch(error => error.message)
  }
  
  const remove_event_comment = async (event_id, comment_id) => {
    db.collection("events").doc(event_id).collection("comments").doc(comment_id).delete();
  }



/// 

const add_joiner = async (user_id, event_id) => {
    db.collection("events").doc(event_id).update({members: firebase.firestore.FieldValue.arrayUnion(user_id)})
};
const remove_joiner = async (user_id, event_id) => {
    db.collection("events").doc(event_id).update({members: firebase.firestore.FieldValue.arrayRemove(user_id)})
};
const leave_event = async (user_id, event_id) => {
    db.collection("events").doc(event_id).update({
      members: firebase.firestore.FieldValue.arrayRemove(user_id),
      declined: firebase.firestore.FieldValue.arrayUnion(user_id)
    })
};

const decline_event_invite = async (user_id, event_id) => {
    db.collection("events").doc(event_id).update({
      invites: firebase.firestore.FieldValue.arrayRemove(user_id),
      declined: firebase.firestore.FieldValue.arrayUnion(user_id)
    })
};

const add_request = async (user_data, event_data) => {
    db.collection("events").doc(event_data.id).update({requests: firebase.firestore.FieldValue.arrayUnion(user_data.id)});
    send_event_request_notification(user_data, event_data);
};
const remove_event_request = async (user_id, event_id) => {
    db.collection("events").doc(event_id).update({requests: firebase.firestore.FieldValue.arrayRemove(user_id)});
};
const add_invite = async (user_id, event_data) => {
    db.collection("events").doc(event_data.id).update({invites: firebase.firestore.FieldValue.arrayUnion(user_id)});
    send_event_invite_notification(event_data, user_id);
};

const remove_invite = async (user_id, event_id) => {
    db.collection("events").doc(event_id).update({invites: firebase.firestore.FieldValue.arrayRemove(user_id)});
};

const remove_declined = async (user_id, event_id) => {
    db.collection("events").doc(event_id).update({declined: firebase.firestore.FieldValue.arrayRemove(user_id)});
};

const accept_event_invite = async (user_data, event_data) => {
  remove_invite(user_data.id, event_data.id);
  add_joiner(user_data.id, event_data.id);
  send_new_joiner_notification(user_data, event_data);
}

const accept_event_request = async (user_id, event_data) => {
  try {
      remove_event_request(user_id, event_data.id);
      add_joiner(user_id, event_data.id);
      send_event_request_accepted_notification(event_data, user_id);
      return true;
  } catch(error) {
      return false
  }
};


const make_event_mod = (user_id, event_data) => {
  db.collection("events").doc(event_data.id).update({mods: firebase.firestore.FieldValue.arrayUnion(user_id)})
  send_event_added_mod_notification(event_data, user_id);
};

const remove_event_mod = (user_id, event_data) => {
  db.collection("events").doc(event_data.id).update({mods: firebase.firestore.FieldValue.arrayRemove(user_id)});
  send_event_removed_mod_notification(event_data, user_id)
};


  export { 
      create_event, update_my_event,
      get_events, get_event_data, get_event_comments, 
      add_event_comment, remove_event_comment,
      add_joiner, remove_joiner, leave_event,
      add_request, remove_event_request, 
      remove_declined,
      add_invite, remove_invite, accept_event_invite, decline_event_invite,
      accept_event_request,
      make_event_mod, remove_event_mod

   }



















     
  // const get_nearby_events = async () => {
  //     const current_date = new Date();
  //     const query = geocollection.near({ center: new firebase.firestore.GeoPoint(51.4309209, -0.0936496), radius: 50 }).where("private_status", "==", false);
  //     return query.get().then((snap) => {
  //         return Promise.all(snap.docs.map(async (doc) => {
  //           const joiners = await get_event_members(doc.id);
  //           return {...doc.data(), joiners}    
  //         }));
  //     }).catch(error => error.message)
  // }
