import React from 'react';
import './styles/main.scss';

import Router from './pages/router/Router';

import { AuthProvider } from './contexts/Auth.context';
import { ProfileDataProvider } from './contexts/ProfileData.context';

function App() {
  return (
    <AuthProvider>
      <ProfileDataProvider>
        <Router />
      </ProfileDataProvider>
    </AuthProvider>
  );
}

export default App;
