import React, { createContext, useState, useEffect } from "react";

export const LocationContext = createContext();

export function LocationProvider(props) {
    const [location, set_location] = useState(null);
    const [address, set_address] = useState(null);

    useEffect(() => {
        // let address = await Location.reverseGeocodeAsync({latitude: location.coords.latitude, longitude: location.coords.longitude})
        if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition((location) => {
            set_location({latitude: location.coords.latitude, longitude: location.coords.longitude})
        });
        } 
    }, [])


    const get_miles = (i) => (i*0.000621371192);

    const calculate_distance = (lat1, lon1, lat2 = location.latitude, lon2 = location.longitude) => {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p)/2 + 
                c(lat1 * p) * c(lat2 * p) * 
                (1 - c((lon2 - lon1) * p))/2;
        var kms = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
        return get_miles(kms * 1000).toFixed(1)
    }

    return (
    <LocationContext.Provider value={{ location, address, calculate_distance,   }}>
        {props.children}
    </LocationContext.Provider>
    );

}