import { firebase, db, auth } from '../Firebase';
import { send_dm_notification } from './Notification_Functions';
import { get_user_data } from './User_Functions';


const get_chat = async (user_id, chat_id) => {
    return await db.collection("chats").doc(chat_id).get().then(async (doc) => {
        const item = doc.data();
        const contact_id = item.users.filter(e => e !== user_id)[0];
        const contact_data = await get_user_data(contact_id);
        const blocked = item.deleted.includes(user_id);
        const unread = item.unread === user_id ? true : false;
        return {messages: item.messages, chat_id: item.id, blocked: blocked, contact: contact_data, unread: unread}
    });
};


const search_direct_messages = async (user_id) => {
    return await db.collection("chats").where("users", "array-contains", user_id).orderBy('timestamp', 'desc').get().then(async (querySnapshot) => {
        return await Promise.all(querySnapshot.docs.map(async (doc) => {
            const item = doc.data();
            const contact_id = item.users.filter(e => e !== user_id)[0];
            const contact_data = await get_user_data(contact_id);
            const deleted = item.deleted.includes(user_id);
            const blocked = item.deleted.includes(user_id);
            const unread = item.unread === user_id ? true : false;
            return {messages: item.messages, chat_id: item.id, blocked: blocked, contact: contact_data, unread: unread, deleted: deleted}
        }))
    });
};

const search_contacts_direct_messages = async (user_id, contact_id) => {
    return await db.collection("chats").where("users", "array-contains", contact_id).orderBy('timestamp', 'desc').get().then(async (querySnapshot) => {
        const status = querySnapshot.docs.filter(async (doc) => {
            const item = doc.data();
            return item.users.includes(user_id);
        })
        return status.length > 0 ? status[0].chat_id : false
    });
};

const create_chat = async (chat_id, user_data, contact_id, content) => {
    db.collection("chats").doc(chat_id).set({
        id: chat_id,
        users: [user_data.id, contact_id],
        blocked: [],
        deleted: [],
        messages: [{content: content, sender: user_data.id, type: 0, created: firebase.firestore.Timestamp.fromDate(new Date())}],
        unread: contact_id,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date())
    })
    send_dm_notification(user_data, contact_id, content)
};

const send_direct_message = async (chat_id, user_data, contact_id, content) => {
    send_dm_notification(user_data, contact_id, content)
    try {
        db.collection("chats").doc(chat_id).update({
            messages: firebase.firestore.FieldValue.arrayUnion({content: content, sender: user_data.id, type: 0, created: firebase.firestore.Timestamp.fromDate(new Date())}),
            deleted: [],
            unread: contact_id,
            timestamp: firebase.firestore.Timestamp.fromDate(new Date())
        });
        return true
    } catch(error) {
        return false
    }
}

const read_direct_message = async (chat_id) => {
    try {
        db.collection("chats").doc(chat_id).update({ unread: false });
        return true;
    } catch(error) {
        return false;
    }
}

const delete_chat = async (chat_id, user_id) => {
    try {
        db.collection("chats").doc(chat_id).update({
            deleted: firebase.firestore.FieldValue.arrayUnion(user_id)
        });
        return true;
    } catch(error) {
        return false
    }
}


const toggle_block_chat = async (chat_id, user_id, status) => {
    try {
        if(status === true) unblock_chat(chat_id, user_id);
        if(status === false) block_chat(chat_id, user_id);
        return true;
    } catch(error) {
        return false;
    }
}

const block_chat = async (chat_id, user_id) => {
    db.collection("chats").doc(chat_id).update({
        blocked: firebase.firestore.FieldValue.arrayUnion(user_id)
    });
}

const unblock_chat = async (chat_id, user_id) => {
    db.collection("chats").doc(chat_id).update({
        blocked: firebase.firestore.FieldValue.arrayRemove(user_id)
    });
}

export { search_direct_messages, get_chat, send_direct_message, read_direct_message, create_chat, delete_chat, toggle_block_chat }
