import { storage , storageRef } from '../Firebase';


const upload_avatar = async (file, userId) => {
    const imageRef = storage.ref('avatars/' + userId);
    const task = await imageRef.put(file).then(snapshot => { return snapshot });
    const url = await imageRef.getDownloadURL().then(downloadURL => { return downloadURL });
    return task.state === "success" ? url : false;
  };
  
  const get_avatar = async (userId) => {
    let userPhoto;
    const avatarRef = storageRef.child('avatars/' + userId);
    await avatarRef.getDownloadURL().then((url) => { userPhoto = url});
    return userPhoto;
  };

  const upload_logo = async (file, groupId) => {
    const imageRef = storage.ref('groups/' + groupId);
    const task = await imageRef.put(file).then(snapshot => { return snapshot });
    const url = await imageRef.getDownloadURL().then(downloadURL => { return downloadURL });
    return task.state === "success" ? url : false;
  };

  
  const get_logo = async (groupId) => {
    let logo;
    const avatarRef = storageRef.child('groups/' + groupId);
    await avatarRef.getDownloadURL().then((url) => { logo = url});
    return logo;
  };

  
  const upload_event_image = async (file, eventId) => {
    const imageRef = storage.ref('events/' + eventId);
    const task = await imageRef.put(file).then(snapshot => { return snapshot });
    const url = await imageRef.getDownloadURL().then(downloadURL => { return downloadURL });
    return task.state === "success" ? url : false;
  };
  
  const get_event_image = async (eventId) => {
    let image;
    const avatarRef = storageRef.child('events/' + eventId);
    await avatarRef.getDownloadURL().then((url) => { image = url});
    return image;
  };
  

  export { upload_avatar, get_avatar, upload_logo, get_logo, upload_event_image, get_event_image }