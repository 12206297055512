import styles from '../styles/components/global.module.scss';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";


const Highlight = (props) => (
    <p className={styles.highlight} style={{alignSelf: props.middle ? "auto" : "flex-start"}}>{props.children}</p>
);

const form_header = (title, text) => {
    return (
        <div className={styles.form__header}>
            <p>{title}</p>
            <small>{text}</small>
        </div>
    )
};

const form_selectable = (state, index, text, action) => {
    const handler = () => {
        if(state === index) { action(null) } else { action(index) }
    }
    return (
        <>
        {state !== index && state !== null ? null : <div className={`${styles.form__selectable} ${state === index ? styles.form__selectable__active : null}`} onClick={() => handler()}>
            <small className='medium_dark'>{text}</small>
            <FaChevronRight />
        </div>}
        </>
    )
}

const form_select = (text, action) => {
    return (
        <div className={`${styles.form__selectable}`} onClick={action}>
            <p>{text}</p>
            <FaChevronRight />
        </div>
    )
}

const form_action = (state, index, text, action) => {
    const handler = () => {
        if(state === index) { action(null) } else { action(index) }
    }
    return (
        <>
        {state !== index && state !== null ? null : <div className={`${styles.form__selectable} ${state === index ? styles.form__selectable__active : null}`} onClick={() => handler()}>
            <p>{text}</p>
            <FaChevronRight />
        </div>}
        </>
    )
}

const form_info = (text) => (
    <div className={styles.info}>
        <IoMdInformationCircleOutline />
        <small>{text}</small>
    </div>
);


const Modal = (props) => <section className={styles.modal} style={{height: `${props.height}%`, width: `${props.width}%`}}>{props.children}</section>

const Line = (props) => (
    <div className={styles.line}>
        <small>{props.children}%</small>
        <div className={styles.line__bar}><span style={{width: `${props.children}%`}}></span></div>
    </div>
);


const Table = (props) => <div className={styles.table}>{props.children}</div>

const Grid = (props) => <div className={styles.grid} style={{gap: `${props.gap}rem`, gridTemplateColumns: props.columns, gridTemplateRows: props.rows, justifyItems: `${props.justifyItems}`, height: `${props.height}`, marginBottom: `${props.marginBottom}rem`, marginTop: `${props.marginTop}rem`, paddingLeft: `${props.paddingHorizontal}rem`, paddingRight: `${props.paddingHorizontal}rem`,}}>{props.children}</div>;
const Grid_Header = (props) => <h4 className={styles.header}>{props.children}</h4>;
const Column = (props) => <div className={styles.column} style={{gap: `${props.gap}rem`, marginTop: `${props.marginTop}rem`, marginBottom: `${props.marginBottom}rem`, paddingBottom: `${props.paddingBottom}rem`, padding: `${props.padding}rem`, height: `${props.height}`, width: `${props.width}`, overflow: `${props.fixed ? "initial" : "auto"}`, paddingRight: `${props.fixed ? "0" : "1rem"}`, textAlign: `${props.centerText ? "center" : "initial"}`}} >{props.children}</div>;
const ColumnCentered = (props) => <div className={styles.column} style={{gap: `${props.gap}rem`, marginTop: `${props.marginTop}rem`, alignItems: "center"}}>{props.children}</div>;
const ColumnSpaced = (props) => <div className={styles.columnSpaced} style={{gap: `${props.gap}rem`, marginTop: `${props.marginTop}rem`, marginBottom: `${props.marginBottom}rem`, paddingBottom: `${props.paddingBottom}rem`, padding: `${props.padding}rem`, height: `${props.height}`,  overflow: `${props.fixed ? "initial" : "auto"}`, paddingRight: `${props.fixed ? "0" : "1rem"}`, textAlign: `${props.centerText ? "center" : "initial"}`}}  >{props.children}</div>;
const Row = (props) => <div className={styles.row} style={{gap: `${props.gap}rem`, marginTop: `${props.marginTop}rem`, marginTop: props.marginTopAuto ? "auto" : null, alignItems: `${props.start ? "flex-start" : "center"}`, overflowX: `${props.overflow ? "auto" : null}`, height: `${props.height}`, paddingRight: `${props.overflow ? "2.5rem" : null}`, flexWrap: props.nowrap ? "nowrap" : "wrap"}}>{props.children}</div>
const RowCentered = (props) => <div className={styles.row} style={{gap: `${props.gap}rem`, marginTop: `${props.marginTop}rem`, marginTop: props.marginTopAuto ? "auto" : null, alignItems: `${props.start ? "flex-start" : "center"}`, justifyContent: 'center', overflowX: `${props.overflow ? "auto" : null}`, paddingRight: `${props.overflow ? "2.5rem" : null}`, flexWrap: props.nowrap ? "nowrap" : "wrap"} }>{props.children}</div>
const RowSpaced = (props) => <div className={styles.rowSpaced} style={{gap: `${props.gap}rem`, marginTop: props.marginTopAuto ? "auto" : null, marginTop: `${props.marginTop}rem`, alignItems: `${props.start ? "flex-start" : "center"}`, flexWrap: props.nowrap ? "nowrap" : "wrap"}}>{props.children}</div>

export { form_header, form_selectable, form_select, form_info, Grid, Column, ColumnSpaced, ColumnCentered, Grid_Header, Row, RowSpaced, RowCentered, Modal, Line, Table, Highlight }