import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import * as geofirestore from 'geofirestore';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBlfLFzrRlLHpmk0fPrEOTMvZSzlPnWI8U",
  authDomain: "advently.co.uk",
  databaseURL: "https://forage-212715.firebaseio.com",
  projectId: "forage-212715",
  storageBucket: "forage-212715.appspot.com",
  messagingSenderId: "959656286292",
  appId: "1:959656286292:web:1c0f07e3223407e4"
});

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const functions = firebaseApp.functions();
const storage = firebaseApp.storage();
const storageRef = storage.ref();

const firestore = firebase.firestore();

const GeoFirestore = geofirestore.initializeApp(firestore);



export { firebase, db, auth, functions, storage, storageRef, GeoFirestore };