import { db, firebase, GeoFirestore } from '../Firebase';
import { send_added_mod_notification, send_group_invite_notification, send_group_request_accepted_notification, send_group_request_notification, send_message_added_notification, send_new_member_notification, send_removed_mod_notification } from './Notification_Functions';

const geocollection = GeoFirestore.collection('groups');

const create_group = async ({name, type, about, user_id, logo, location, geo, id}) => { 
  return await db.collection("groups").doc(id).set({
      id: id,
      name: name,
      type: type,
      about: about,
      location: location, 
      geo: geo,
      image: logo, 
      admin: user_id,
      mods: [user_id],
      members: [user_id], 
      invites: [],
      requests: [],
      declined: []      
  }).then(async () => {
    await geo_add_data(id, geo)
    return true
  })
  .catch((error) => {
      return false
  });
}

const geo_add_data = async (group_id, geo) => {
geocollection.doc(group_id).update({
  coordinates: new firebase.firestore.GeoPoint(geo.latitude, geo.longitude)
})
}

const get_group_data = async (group_id) => {
    const group_data = await db.collection("groups").doc(group_id).get().then(doc =>  doc.data());  
    const upcoming = await get_group_upcoming(group_id);
    return {...group_data, upcoming};
};

const update_my_group = async (group_id, new_info) => {
    return db.collection("groups").doc(group_id).update(new_info).then(async () => {
        await geo_add_data(group_id, new_info.geo)
        return true
    }).catch(error => {
        return error.message
    });
};
  
const get_groups = async () => {
    return await db.collection("groups").get().then(async (querySnapshot) => {
      return await Promise.all(querySnapshot.docs.map(async (doc) => {
        const upcoming = await get_group_upcoming(doc.id);
        return {...doc.data(), upcoming}     
      }));
    }).catch(error => error.message)
}


const get_group_events = async (group_id) => {
    return await db.collection("events").where("group_id", "==", group_id).get().then(async (querySnapshot) => {
      return await Promise.all(querySnapshot.docs.map(async (doc) => doc.data()))
    });
}

const get_groups_templates = async (group_id) => {
    return await db.collection("events").where("group_id", "==", group_id).where("template", "==", true).get().then(async (querySnapshot) => {
      return await Promise.all(querySnapshot.docs.map(async (doc) => doc.data()))
    });
}

const get_group_upcoming = async (group_id) => {
    const current_date = new Date();
    return await db.collection("events").where("group_id", "==", group_id).where("start", ">=", current_date).get().then(async (querySnapshot) => {
      return await Promise.all(querySnapshot.docs.map(async (doc) => doc.data()))
    });
}
const get_group_history = async (group_id) => {
    const current_date = new Date();
    return await db.collection("events").where("group_id", "==", group_id).where("start", "<", current_date).get().then(async (querySnapshot) => {
      return await Promise.all(querySnapshot.docs.map(async (doc) => doc.data()))
    });
}


  const get_messages = async (group_id) => {
    return db.collection("groups").doc(group_id).collection("messages").get().then(querySnapshot => {
        return querySnapshot.docs.map(doc => doc.data())
      })
  }

  const add_message = async (group_id, group_name, user_data, id, content) => {
    return db.collection("groups").doc(group_id).collection("messages").doc(id).set({
      ref_id: user_data.id,
      send_id: group_id,
      send_name: group_name,
      content: content,
      message_id: id,
      created: firebase.firestore.Timestamp.fromDate(new Date())
    }).then(() => {
      send_message_added_notification(user_data, group_id, content);
      return true
    }).catch(error => error.message)
  }

  const remove_message = async (group_id, comment_id) => {
    db.collection("groups").doc(group_id).collection("messages").doc(comment_id).delete();
  }


  //// 

const add_member = async (user_id, group_id) => {
    db.collection("groups").doc(group_id).update({members: firebase.firestore.FieldValue.arrayUnion(user_id)})
};
const remove_member = async (user_id, group_id) => {
    db.collection("groups").doc(group_id).update({members: firebase.firestore.FieldValue.arrayRemove(user_id)})
};
const add_request = async (user_data, group_data) => {
    db.collection("groups").doc(group_data.id).update({requests: firebase.firestore.FieldValue.arrayUnion(user_data.id)})
    send_group_request_notification(user_data, group_data);
};
const remove_group_request = async (user_id, group_id) => {
    db.collection("groups").doc(group_id).update({requests: firebase.firestore.FieldValue.arrayRemove(user_id)})
};
const add_invite = async (user_id, group_data) => {
    db.collection("groups").doc(group_data.id).update({invites: firebase.firestore.FieldValue.arrayUnion(user_id)})
    send_group_invite_notification(group_data, user_id)
};
const remove_invite = async (user_id, group_id) => {
    db.collection("groups").doc(group_id).update({invites: firebase.firestore.FieldValue.arrayRemove(user_id)})
};

const accept_invite = async (user_data, group_data) => {
  try {
    remove_invite(user_data.id, group_data.id);
    add_member(user_data.id, group_data.id);
    send_new_member_notification(user_data, group_data);
    return true;
  } catch(error) {
    return false;
  }
}

const accept_group_request = async (user_id, group_data) => {
  try {
      remove_group_request(user_id, group_data.id);
      add_member(user_id, group_data.id);
      send_group_request_accepted_notification(group_data, user_id)
      return true;
  } catch(error) {
      return false
  }
};

const make_group_mod = (user_id, group_data) => {
    db.collection("groups").doc(group_data.id).update({mods: firebase.firestore.FieldValue.arrayUnion(user_id)})
    send_added_mod_notification(group_data, user_id);
};

const remove_group_mod = (user_id, group_data) => {
    db.collection("groups").doc(group_data.id).update({mods: firebase.firestore.FieldValue.arrayRemove(user_id)})
    send_removed_mod_notification(group_data, user_id);
};


export { 
    get_group_data, update_my_group, get_groups, create_group,
    get_group_history, get_group_upcoming, get_group_events, get_groups_templates,
    add_message, remove_message, get_messages,
    add_member, remove_member, 
    add_request, remove_group_request, 
    add_invite, remove_invite, accept_invite,
    accept_group_request,
    make_group_mod, remove_group_mod
}