import { firebase, db } from '../Firebase';
import { get_event_data } from './Event_Functions';
import { get_group_data } from './Group_Functions';
import { get_user_data } from './User_Functions';

const get_user_token = async (user_id) => {
  return db.collection("users").doc(user_id).get().then(doc => {
      const data = doc.data();
      return {token: data.device_id, status: data.dnd_notifications}
  })
}

const read_notes = (user_id) => {
    db.collection("users").doc(user_id).collection("notifications").get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        var noteRef = db.collection("users").doc(user_id).collection("notifications").doc(doc.id);
        return noteRef.update({ is_read: true });
    });
  });
};



const open_message = (user_id, contact_id) => {
    db.collection("users").doc(user_id).collection("notifications").get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        var noteRef = db.collection("users").doc(user_id).collection("notifications").doc(doc.id);
        return doc.data().type === 3 && doc.data().id === contact_id && doc.data().opened === false ? noteRef.update({ opened: true }) : null;
    });
  });
};




const delete_note = (user_id, note_id) => {
    db.collection("users").doc(user_id).collection("notifications").doc(note_id).delete();
};



///////////////////////////////////////




const send_dm_notification = async (user_data, contact_id, content) => {
    
    const notification = {
      ref_id: user_data.id,
      type: 3,
      image: user_data.image,
      title: `${user_data.name} direct messaged you.`,
      message: `${content.substring(0, 50)}`,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      is_read: false
  }

  create_notification(notification, contact_id);
}



//////////////////////////////////



const send_event_request_notification = async (user_data, event_data) => {

  const notification = {
    ref_id: user_data.id,
    type: 4,
    image: user_data.image,
    title: `${event_data.name}`,
    message: `${user_data.name} requested to join ${event_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

event_data.mods.forEach(item => create_notification(notification, item));
}

const send_group_request_notification = async (user_data, group_data) => {

  const notification = {
    ref_id: user_data.id,
    type: 4,
    image: user_data.image,
    title: `${group_data.name}`,
    message: `${user_data.name} requested to join ${group_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

  group_data.mods.forEach(item => create_notification(notification, item));
}

const send_friend_request_notification = async (user_data, ref_id) => {
  const notification = {
    ref_id: user_data.id,
    type: 4,
    image: user_data.image,
    title: `Friend Request`,
    message: `${user_data.name} requested to be friends`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}
  create_notification(notification, ref_id)
}


////////////////////////////////////////////////////////

const send_friend_request_accepted_notification = async (user_data, ref_id) => {
  const notification = {
    ref_id: user_data.id,
    type: 0,
    image: user_data.image,
    title: `New Friend`,
    message: `You've become friends with ${user_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}
  create_notification(notification, ref_id)
}

const send_group_request_accepted_notification = async (group_data, ref_id) => {
  const notification = {
    ref_id: group_data.id,
    type: 1,
    image: group_data.image,
    title: `Community`,
    message: `${group_data.name} has been added to your Communities`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}
  create_notification(notification, ref_id)
}

const send_event_request_accepted_notification = async (event_data, ref_id) => {
  const notification = {
    ref_id: event_data.id,
    type: 2,
    image: event_data.image,
    title: `Upcoming`,
    message: `${event_data.name} has been added to your upcoming events`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}
  create_notification(notification, ref_id)
}

//////////////////////////////////////////////////////////////

const send_event_invite_notification = async (event_data, ref_id) => {

  const notification = {
    ref_id: event_data.id,
    type: 2,
    image: event_data.image,
    title: `Invite`,
    message: `You've been invited you to join ${event_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

  create_notification(notification, ref_id)
}

const send_group_invite_notification = async (group_data, ref_id) => {
  const notification = {
    ref_id: group_data.id,
    type: 1,
    image: group_data.image,
    title: `Invite`,
    message: `You've been invited you to join ${group_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}
  create_notification(notification, ref_id)
}

///////////////////////////////////////////////

const send_new_joiner_notification = async (user_data, event_data) => {
  const notification = {
      ref_id: event_data.id,
      type: 2,
      image: user_data.image,
      title: `${event_data.name}`,
      message: `${user_data.name} has joined ${event_data.name}`,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      is_read: false
  }
  create_notification(notification, event_data.admin)
}

const send_new_member_notification = async (user_data, group_data) => {

  const notification = {
    ref_id: group_data.id,
    type: 1,
    image: user_data.image,
    title: `${group_data.name}`,
    message: `${user_data.name} has joined ${group_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

create_notification(notification, group_data.admin);
}


//////////////////////////////////////////////

const send_added_mod_notification = async (group_data, ref_id) => {
  const notification = {
    ref_id: group_data.id,
    type: 1,
    image: group_data.image,
    title: `${group_data.name}`,
    message: `You are now a moderator of ${group_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

create_notification(notification, ref_id);
}

const send_event_added_mod_notification = async (event_data, ref_id) => {
  const notification = {
    ref_id: event_data.id,
    type: 2,
    image: event_data.image,
    title: `${event_data.name}`,
    message: `You are now a moderator of ${event_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

create_notification(notification, ref_id);
}

const send_removed_mod_notification = async (group_data, ref_id) => {
  const notification = {
    ref_id: group_data.id,
    type: 1,
    image: group_data.image,
    title: `${group_data.name}`,
    message: `You are no longer a moderator of ${group_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

create_notification(notification, ref_id);
}

const send_event_removed_mod_notification = async (event_data, ref_id) => {
  const notification = {
    ref_id: event_data.id,
    type: 2,
    image: event_data.image,
    title: `${event_data.name}`,
    message: `You are no longer a moderator of ${event_data.name}`,
    created: firebase.firestore.Timestamp.fromDate(new Date()),
    is_read: false
}

create_notification(notification, ref_id);
}


//////////////////////////////////////////////////

const send_event_date_changed = async (event_id) => {
    const event_data = await get_event_data(event_id);

    const notification = {
      ref_id: event_data.id,
      type: 2,
      image: event_data.image,
      title: `${event_data.name}`,
      message: `${event_data.name} has been rescheduled`,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      is_read: false
  }

    event_data.members.forEach(item => create_notification(notification, item));

}

////////////////////////////////////////////////////////


const send_comment_added_notification = async (user_data, event_id, content) => {
    const event_data = await get_event_data(event_id);

    const notification = {
      ref_id: event_data.id,
      type: 2,
      image: event_data.image,
      title: `${user_data.name} commented on ${event_data.name}`,
      message: `${content.substring(0, 50)}`,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      is_read: false
  }

    event_data.members.filter(item => item !== user_data.id).forEach(item => create_notification(notification, item));

}

const send_message_added_notification = async (user_data, group_id, content) => {
    const group_data = await get_group_data(group_id);

    const notification = {
      ref_id: group_data.id,
      type: 1,
      image: group_data.image,
      title: `${user_data.name} commented on ${group_data.name}`,
      message: `${content.substring(0, 50)}`,
      created: firebase.firestore.Timestamp.fromDate(new Date()),
      is_read: false
  }

    group_data.members.filter(item => item !== user_data.id).forEach(item => create_notification(notification, item));

}

////////////////////////////////////////////////////////

const create_notification = async (note, user_id) => {

  // const user_info = await get_user_token(user_id);
  // const message = {
  //       "to": user_info.token,
  //       "sound": "default",
  //       "title": note.title,
  //       "body": note.message
  //   };
  //   if(user_info.status === false) {
  //       fetch('https://exp.host/--/api/v2/push/send', {
  //           method: 'POST',
  //           headers: {
  //               'host': 'exp.host',
  //               'Accept': 'application/json',
  //               'accept-encoding': 'gzip, deflate',
  //               'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify(message)
  //       });
  //   }
  db.collection("users").doc(user_id).collection("notifications").add(note);
}

export { 
  read_notes, delete_note, open_message,
  send_dm_notification, 
  //EVENTS
  send_event_request_notification,
  send_group_request_notification,
  send_friend_request_notification,

  send_friend_request_accepted_notification,
  send_group_request_accepted_notification, 
  send_event_request_accepted_notification, 

  send_event_invite_notification, 
  send_group_invite_notification,

  send_new_joiner_notification, 
  send_new_member_notification,

  send_added_mod_notification, 
  send_event_added_mod_notification,
  send_removed_mod_notification,
  send_event_removed_mod_notification, 

  send_event_date_changed,
  send_comment_added_notification,
  send_message_added_notification,


 }



