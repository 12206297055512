import React, { useState } from 'react';
import styles from '../../styles/components/screens/Create.module.scss';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
const GOOGLE_PLACES_API_KEY = 'AIzaSyD3p-zqgG7xGncibuSWoxBkHCPea1RyuKk'; // never save your real api key in a snack!

const GoogleAddress = (props) => {
    const address = props.address;

    const select_handler = (e) => {
        props.set_address(e.label)
        geocodeByPlaceId(e.value.place_id)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>
                props.geo({ latitude: lat, longitude: lng })
            );
    };

    return (
        <div className={styles.force}>
            <GooglePlacesAutocomplete apiKey={GOOGLE_PLACES_API_KEY} selectProps={{address, onChange: (e) => select_handler(e), 
            
                styles: {
                    container: (provided) => ({
                        ...provided,
                        marginTop: 1,
                        marginLeft: 1,
                      }),
                    control: (provided, state) => ({
                        ...provided,
                        color: "#ff8866",
                        backgroundColor: "#F9F9FB",
                        borderRadius: "1.5rem", 
                        width: "100%",
                        height: "5rem",
                        borderWidth: 1.5,
                        paddingLeft: 10, 
                        paddingRight: 10,
                        boxShadow: 0,
                        borderColor: state.isFocused ?  "#ff8866" : "#CDCDCD",
                      }),
                    input: (provided, state) => ({
                        ...provided,
                        color: '#313234',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: '#313234',
                        backgroundColor: state.isFocused ?  "#ff8866" : "#fff",
                        borderRadius: "1rem",
                        marginLeft: 10, 
                        marginRight: 10,
                        width: "97%",
                        fontSize: 15
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        color: '#313234',
                        fontSize: 15
                      }),
                }
            }} 
                
            />
        </div>      
    )
};

export default GoogleAddress;


// geocodeByAddress(e.label)
// .then(results => getLatLng(results[0]))
// .then(({ lat, lng }) =>
//     console.log({ lat, lng })
// );