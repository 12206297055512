import { db, auth, storage, storageRef, firebase } from '../Firebase';
import { get_group_events } from './Group_Functions';
import { get_event_admins, get_group_admins } from './User_Functions';


//////////////////////// DELETE USER /////////////////////

const delete_account = async (user_id) => {


    const groups = await get_group_admins(user_id);
    const events = await get_event_admins(user_id);
    groups.forEach(async (group) => await delete_group(group.id));
    events.forEach(async (event) => await delete_event(event.id))
    return await removeUsersEvents(user_id)
    .then(async () => await removeUsersEvents(user_id))
    .then(async () => await removeUsersGroups(user_id))
    .then(async () => await removeUsersNotifications(user_id))
    .then(async () => await removeUsersFriendships(user_id))
    .then(async () => await removeUsersChats(user_id))
    .then(async () => await delete_avatar(user_id))
    .then(async () => { await db.collection("users").doc(user_id).delete()})
    .then(() => { auth.currentUser.delete()}) 
}

  
  async function removeUsersEvents(user_id) {
    await db.collection("events").where("members", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({members: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("events").where("invites", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({invites: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("events").where("declined", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({declined: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("events").where("mods", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({mods: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("events").where("requests", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({requests: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
  }

  async function removeUsersGroups(user_id) {
    await db.collection("groups").where("members", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({members: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("groups").where("invites", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({invites: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("groups").where("declined", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({declined: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("groups").where("mods", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({mods: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("groups").where("requests", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({requests: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
  }


  async function removeUsersNotifications(user_id){
    await db.collection("users").doc(user_id).collection("notifications").get().then(querySnapshot => {
      querySnapshot.forEach(doc => doc.ref.delete())
    })
  }
  

  async function removeUsersFriendships(user_id){
    await db.collection("users").where("requests", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({requests: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
    await db.collection("users").where("friends", "array-contains", user_id).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.update({friends: firebase.firestore.FieldValue.arrayRemove(user_id)})
      });
    })
  }

  async function removeUsersChats(user_id){
    await db.collection("chats").where("users", "array-contains", user_id).get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.delete())
    });
  }




  const delete_avatar = async (user_id) => {
    const avatarRef = storageRef.child('avatars/' + user_id);
    try {
      await avatarRef.delete(); 
    } catch(error) {
      return
    }
  };



  ////////////////////////////////////////////// GROUP 


  
const delete_group = async (group_id) => {
    console.log(group_id)
    return await get_group_events(group_id)
    .then(events => { console.log(events); events.forEach(async (event) => await delete_event(event.id)) })
    .then(async () => { await delete_group_messages(group_id) })
    .then(async () => { await delete_logo(group_id) })
    .then(async () => await db.collection("groups").doc(group_id).delete())
    .then(async () => { return true })
    .catch((error) => { return false })  
}

  

  async function delete_group_messages(group_id){
    await db.collection("groups").doc(group_id).collection("messages").get().then(querySnapshot => {
      querySnapshot.forEach(doc => doc.ref.delete());
    })
  }

  const delete_logo = async (group_id) => {
    const logoRef = storageRef.child('groups/' + group_id);
    await logoRef.delete().then(() => {
      return "delete successssss"
    }).catch((error) => {
      return error
    });;
  };

  //////////////////////////////////////


  

async function delete_event(event_id){
    return await removeEventsComments(event_id)
    .then(async () => await delete_image(event_id))
    .then(db.collection("events").doc(event_id).delete())
    .then(() => { return true })
    .catch(() => { return false })   
}


async function removeEventsComments(event_id){
  await db.collection("events").doc(event_id).collection("comments").get().then(querySnapshot => {
    querySnapshot.forEach(doc => doc.ref.delete());
  }) 
}


const delete_image = async (event_id) => {
  const imageRef = storageRef.child('events/' + event_id);
  await imageRef.delete(); 
};


export { delete_account, delete_group, delete_event, delete_logo }