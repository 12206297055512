import { firebase, db, auth } from '../Firebase';
import { get_group_data } from './Group_Functions';
import { send_friend_request_accepted_notification, send_friend_request_notification } from './Notification_Functions';


////// AUTH FUNCTIONS

const verify_user_email = () => auth.currentUser.sendEmailVerification();

const change_my_password = async (new_password) => {
    return auth.currentUser.updatePassword(new_password);   
};
    
const reset_password = async (email) => {
    return auth.sendPasswordResetEmail(email);
};
    
const authenticate_me = async (password) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, password); 
    return auth.currentUser.reauthenticateWithCredential(credential);
};


//// USER FUNCTIONS

const create_user_profile = async (user_id, user_info) => {
    return db.collection("users").doc(user_id).set({
        id: user_id,
        email: user_info.email,
        name: user_info.name,
        age: null,
        image: "https://firebasestorage.googleapis.com/v0/b/forage-212715.appspot.com/o/default-image.png?alt=media&token=a420c3be-5332-4396-9369-f5853c6ed3f2", 
        about: "",
        location: null, 
        private: false,
        saved: [],
        dnd_notifications: false,
        dnd_emails: false,
        search_radius: 25,
        blocked: [],
        requests: [],
        friends: [], 
        active: firebase.firestore.Timestamp.fromDate(new Date()),
    }).then(async () => {
        auth.currentUser.sendEmailVerification();
        return true;
    }).catch((error) => {
        return false
    });
}   

const get_user_data = async (user_id) => {
    return await db.collection("users").doc(user_id).get().then(doc => doc.data());
}

const update_my_profile = (user_id, new_info) => {
    try { db.collection("users").doc(user_id).update(new_info); return true }
    catch(error) { return error.message }
}

const update_active = (user_id) => {
    db.collection("users").doc(user_id).update({active: firebase.firestore.Timestamp.fromDate(new Date())});
}

const toggle_event_save = (user_id, event_id, status) => {
    if(status === true) return db.collection("users").doc(user_id).update({saved: firebase.firestore.FieldValue.arrayUnion(event_id)})
    if(status === false) return db.collection("users").doc(user_id).update({saved: firebase.firestore.FieldValue.arrayRemove(event_id)})
}


const query_users = async (query) => {
    return await db.collection("users").where("name", ">=", query).where('name', '<=', query+ '\uf8ff').get().then(async (querySnapshot) => {
        return querySnapshot.docs.map(doc => doc.data());
    })
}

const get_all_users = async () => {
    return await db.collection("users").get().then(querySnapshot => {
        return querySnapshot.docs.map(doc => doc.data());
    });
};


const add_request = async (user_data, ref_id) => {
    db.collection("users").doc(ref_id).update({requests: firebase.firestore.FieldValue.arrayUnion(user_data.id)})
    send_friend_request_notification(user_data, ref_id)
};

const remove_request = async (user_id, ref_id) => {
    db.collection("users").doc(ref_id).update({requests: firebase.firestore.FieldValue.arrayRemove(user_id)})
};

const accept_request = async (user_data, ref_id) => {
    db.collection("users").doc(user_data.id).update({
        requests: firebase.firestore.FieldValue.arrayRemove(ref_id),
        friends: firebase.firestore.FieldValue.arrayUnion(ref_id)
    });
    db.collection("users").doc(ref_id).update({friends: firebase.firestore.FieldValue.arrayUnion(user_data.id)});
    send_friend_request_accepted_notification(user_data, ref_id)
};


const remove_friend = async (user_id, ref_id) => {
    db.collection("users").doc(user_id).update({friends: firebase.firestore.FieldValue.arrayRemove(ref_id)})
    db.collection("users").doc(ref_id).update({friends: firebase.firestore.FieldValue.arrayRemove(user_id)})
};

const add_friend = async (user_id, ref_id) => {
    db.collection("users").doc(user_id).update({friends: firebase.firestore.FieldValue.arrayUnion(ref_id)})
    db.collection("users").doc(ref_id).update({friends: firebase.firestore.FieldValue.arrayUnion(user_id)})
};

const get_properties = async (user_id) => {
    const e_admins = db.collection("groups").where("admin", "==", user_id).get().then(querySnapshot => querySnapshot.docs.map(doc => doc.data()));
    const e_mods = db.collection("groups").where("mods", "array-contains", user_id).get().then(querySnapshot => querySnapshot.docs.map(doc => doc.data()));
    return {...e_admins, ...e_mods}
};

const get_users_communities = async (user_id) => {
    return db.collection("groups").where("members", "array-contains", user_id).get().then(querySnapshot => {
        return querySnapshot.docs.map(doc => doc.data());
    });
}

const get_users_events = async (user_id) => {
    return db.collection("events").where("members", "array-contains", user_id).get().then(async (querySnapshot) => {
        return await Promise.all(querySnapshot.docs.map(async (doc) => {
            const d = doc.data();
            const organiser = d.group_id ? await get_group_data(d.group_id) : await get_user_data(d.admin);
            return {...d, organiser}  
        }));
    });
}

const get_users_upcoming = async (user_id) => {
    const current_date = new Date();
    return db.collection("events").where("members", "array-contains", user_id).where("start", ">=", current_date).get().then(async (querySnapshot) => {
        return await Promise.all(querySnapshot.docs.map(async (doc) => {
            const d = doc.data();
            const organiser = d.group_id ? await get_group_data(d.group_id) : await get_user_data(d.admin);
            return {...d, organiser}  
        }));
    });
}

const get_users_history = async (user_id) => {
    const current_date = new Date();
    return db.collection("events").where("members", "array-contains", user_id).where("start", "<", current_date).get().then(async (querySnapshot) => {
        return await Promise.all(querySnapshot.docs.map(async (doc) => {
            const d = doc.data();
            const organiser = d.group_id ? await get_group_data(d.group_id) : await get_user_data(d.admin);
            return {...d, organiser}  
        }));
    });
}

const get_event_admins = async (user_id) => {
    return db.collection("events").where("admin", "==", user_id).get().then(async (querySnapshot) => {
      return querySnapshot.docs.map(doc => doc.data())    
    });
}
const get_group_admins = async (user_id) => {
    return db.collection("groups").where("admin", "==", user_id).get().then(async (querySnapshot) => {
      return querySnapshot.docs.map(doc => doc.data())    
    });
}


export {
    verify_user_email, change_my_password, reset_password, authenticate_me,
    get_user_data, update_active, 
    create_user_profile, update_my_profile, toggle_event_save,
    query_users,
    get_all_users,
    add_request, remove_request, accept_request, remove_friend, 
    get_properties, 
    get_users_communities, 
    get_users_events, get_users_upcoming, get_users_history,
    get_event_admins, get_group_admins,
    add_friend
}
